import Vue from "vue";

Vue.mixin({
  methods: {
    createImageURL(val) {
      if (val?.includes("http")) {
        return val;
      }
        return this.$store.getters.baseURL + val;
    },
    validateEmail(mail) {
      const reg = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
      return reg.test(mail);
    },
    declinationOfWords(value, words) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if (value > 10 && value < 20) return words[2];
      if (num > 1 && num < 5) return words[1];
      if (num == 1) return words[0];
      return words[2];
    },
    getInformationImage(image) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image();
          img.src = URL.createObjectURL(image);
          img.onload = () => {
            const newObj = {
              width: img.width,
              height: img.height,
            };
            resolve(newObj);
          };
        } catch (e) {
          reject(e);
        }
      });
    },
    wordTruncation(word, from, to) {
      if (word.length > to) {
        word = word.slice(from, to);
        word += "...";
      }
      return word;
    },

    async involvePagination(paginationSupply, store) {
      const pagination = paginationSupply.paginationParam;
      let items = paginationSupply.itemsParam;
      if (pagination.hasNextPage) {
        try {
          const response = await fetch(
            `${store.apiV7}/${paginationSupply.api}?take=${pagination.take}&page=${pagination.page}`,
            {
              method: "GET",
              headers: {
                authorization: store.adminPanelHeaderAuth,
              },
            }
          );
          const data = await response.json();
          if (paginationSupply.isScroll) {
            items = [...items, ...data.data];
            pagination.page = data.page;
          } else {
            items = data.data;
          }
          if (data.totalPage === data.page) {
            pagination.hasNextPage = false;
          }
          return { itemsNew: items, paginationNew: pagination };
        } catch (error) {
          console.error(error);
        }
      }
      return {
        itemsNew: paginationSupply.itemsParam,
        paginationNew: pagination,
      };
    },

    createDebounce() {
      let timeout = null;
      return (fnc, delayMs) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
  },
});
