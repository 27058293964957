import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import Axios from "axios";
import "@/mixins/mixin";
import moment from "moment";
import VueMask from "v-mask";
import Table from "@/components/main/table/Table";
import DialogImage from "@/components/main/dialog/DialogImage";
import Dialog from "@/components/main/dialog/Dialog";
import TextInput from "@/components/main/inputs/TextInput";
import ImageInput from "@/components/main/inputs/ImageInput";
import TextArea from "@/components/main/inputs/TextArea";
import SelectInput from "@/components/main/inputs/SelectInput";
import FileInput from "@/components/main/inputs/FileInput";
import CheckBoxInput from "@/components/main/inputs/CheckBoxInput";
import SwitchInput from "@/components/main/inputs/SwitchInput";
import Trumbowyg from "vue-trumbowyg";

import "trumbowyg/dist/ui/trumbowyg.css";
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;
Vue.component("Table", Table);
Vue.component("Dialog", Dialog);
Vue.component("DialogImage", DialogImage);
Vue.component("TextInput", TextInput);
Vue.component("ImageInput", ImageInput);
Vue.component("TextArea", TextArea);
Vue.component("SelectInput", SelectInput);
Vue.component("FileInput", FileInput);
Vue.component("CheckBoxInput", CheckBoxInput);
Vue.component("SwitchInput", SwitchInput);

Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(VueMask);
Vue.use(Trumbowyg);

Vue.prototype.$axios = Axios;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("moment", function (value, format) {
  if (value === null || value === undefined || format === undefined) {
    return "";
  }
  if (format === "from") {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
});
